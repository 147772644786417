// src/components/LandingPage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LandingPage.css';

const LandingPage = () => {
    const [trackingNumber, setTrackingNumber] = useState('');
    const navigate = useNavigate();

    const handleInputChange = (e) => {
        setTrackingNumber(e.target.value);
    };

    const handleSubmit = () => {
        // Redirect to TrackingPage only if the trackingNumber is not empty or contains only whitespace
        if (trackingNumber.trim()) {
            navigate(`/tracking?number=${trackingNumber}`);
        }
    };

    return (
        <div className="panel">
            <h101>Document Tracker</h101>
            <p>Official Tracker for DTIS - Document Tracking Information System © 2024</p>
            <input
                type="text"
                placeholder="Enter Tracking Number"
                value={trackingNumber}
                onChange={handleInputChange}
            />
            <button onClick={handleSubmit} disabled={!trackingNumber.trim()}>
                Track
            </button>
            <br></br>
            <p2>BD: 01192024</p2>
        </div>
    );
};

export default LandingPage;
