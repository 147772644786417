// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';  // Note the import change from Switch to Routes
import LandingPage from './components/LandingPage';
import TrackingPage from './components/TrackingPage';
import NotFoundPage from './components/NotFoundPage';

function App() {
  return (
    <Router>
      <Routes>  {/* Change from Switch to Routes */}
        <Route path="/" element={<LandingPage />} />
        <Route path="/tracking" element={<TrackingPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>  {/* Change from Switch to Routes */}
    </Router>
  );
}

export default App;
