// src/components/TrackingPage.js
import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import './TrackingPage.css';

const TrackingPage = () => {
    const location = useLocation();
    const trackingNumber = new URLSearchParams(location.search).get('number');
    const [trackingData, setTrackingData] = useState(null);
    const [noDataFound, setNoDataFound] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const baseURL = process.env.REACT_APP_BASE_URL;
            const apiEndpoint = `${baseURL}/dtis/search?keyword=${trackingNumber}`;
            console.log('$$apiEndpoint', apiEndpoint);
            try {
                const response = await fetch(apiEndpoint);
                const data = await response.json();
                if (data.data.length > 0) {
                    setTrackingData(data.data);
                } else {
                    console.log('$$no_data_found');
                    setNoDataFound(true);
                }

            } catch (error) {
                console.error('Error fetching tracking data:', error);
            }
        };

        fetchData();
    }, [location.search]);

    return (
        <>
            <div className="panel">
                <div className="panel-content">
                    {trackingData ? (
                        <>
                            <div className="label">Tracking Number:</div>
                            <div className="value">{String(trackingNumber).toUpperCase()}</div>

                            <div className="label">Transaction Particulars:</div>
                            <div className="value">{trackingData[0].transaction_particulars}</div>

                            <div className="label">Latest Status:</div>
                            <div className="value">{trackingData[0].transaction_latest_status}</div>
                        </>
                    ) : (
                        noDataFound ?
                            (
                                <div className="no-data">
                                    <p>No data found!</p>
                                </div>
                            ) :
                            (<p>Loading tracking data...</p>)

                    )}
                </div>
            </div>
            {trackingData ? (
                <>
                    <div className="panel">
                        <div className="panel-content">
                            {trackingData ? (
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Action</th>
                                            <th>Status</th>
                                            <th>User</th>
                                            <th>Office</th>
                                            <th>Forwarded To</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {trackingData.map((transaction, index) => (
                                            <tr key={index}>
                                                <td>{transaction.transdate}</td>
                                                <td>{transaction.action_taken}</td>
                                                <td>{transaction.status}</td>
                                                <td>{transaction.user}</td>
                                                <td>{transaction.office}</td>
                                                <td>{transaction.office_forwarded_to || 'N/A'}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (<></>)}
                        </div>
                    </div></>
            ) : (<></>)
            }
            <div className="panel-footer">
                {/* Add a link to the base URL */}
                <Link to="/">Go back to Home</Link>
            </div>
        </>
    );
};

export default TrackingPage;