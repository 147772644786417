import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
const getPartOfDay = () => {
  const currentHour = new Date().getHours();
  if (currentHour >= 6 && currentHour < 12) {
    return 'morning';
  } else if (currentHour >= 12 && currentHour < 18) {
    return 'afternoon';
  } else {
    return 'night';
  }
};
document.body.classList.add(getPartOfDay());

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
document.getElementById('root')